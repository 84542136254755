import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "../../styles/components/interactive_path/InteractivePathPOI.scss";
import useMobileDevice from "../../hooks/UseMobileDevice";
import { usePopupContext } from "../../contexts/PopUpContext";

const InteractivePathPOI = ({
	forwardRef,
	xPosition,
	yPosition,
	stroke = "#000000",
	strokeWidth = "4",
	radius,
	fill,
	angle,
	lineLength,
	icon,
	iconBackgroundFill = "#FFFFFF",
	header,
	body,
	textAnchor = "left",
	contentOffset = { x: 8, y: -4 },
	classes = "",
}) => {
	const { showPopup } = usePopupContext();
	const isMobileDevice = useMobileDevice();

	const iconScale = 4.25;

	// Convert angle from degrees to radians
	const angleRad = angle * (Math.PI / 180);

	// Calculate the coordinates of the endpoint based on angle and lineLength
	const endX = xPosition + lineLength * Math.cos(angleRad);
	const endY = yPosition + lineLength * Math.sin(angleRad);

	const poiClicked = () => {
		if (!isMobileDevice) {
			return;
		}
		showPopup({
			title: header,
			message: body,
		});
	};

	return (
		<svg className={"interactive-path-poi " + classes} style={{ overflow: "visible" }} ref={forwardRef} onClick={poiClicked}>
			{/* Draw a line from the circle to the header if header is present */}
			{header && <line x1={xPosition} y1={yPosition} x2={endX} y2={endY} stroke={stroke} strokeWidth={strokeWidth} />}
			{/* Display the icon */}
			{icon && (
				<svg className="interactive-path-icon-background" x={xPosition - (radius * 6) / 2} y={yPosition - (radius * 6) / 2} width={radius * 6} height={radius * 6} viewBox={`0 0 200 200`} xmlns="http://www.w3.org/2000/svg">
					<rect x="0" y="0" width="200" height="200" fill={iconBackgroundFill} />
				</svg>
			)}
			{icon && (
				<svg className="interactive-path-icon" x={xPosition - (radius * iconScale) / 2} y={yPosition - (radius * iconScale) / 2} width={radius * iconScale} height={radius * iconScale} viewBox={`0 0 200 200`} xmlns="http://www.w3.org/2000/svg">
					{icon}
				</svg>
			)}
			{/* Display the circle */}
			<motion.circle className={icon && "interactive-path-circle"} cx={xPosition} cy={yPosition} r={radius} fill={fill} strokeWidth={strokeWidth} />
			{/* Display the header if present */}
			{header && (
				<text className="interactive-path-poi-header" x={endX + (contentOffset.x ? contentOffset.x : 0)} y={endY + (contentOffset.y ? contentOffset.y : 0)} textAnchor={textAnchor}>
					{header}
				</text>
			)}
			{/* Display the body if present */}
			{body && (
				<text className="interactive-path-poi-body" x={endX + (contentOffset.x ? contentOffset.x : 0)} y={endY + (contentOffset.y ? contentOffset.y : 0) + 8} textAnchor={textAnchor}>
					{body.split("<br />").map((line, index) => (
						<tspan key={index} x={endX + (contentOffset.x ? contentOffset.x : 0)} dy="1.2em">
							{line}
						</tspan>
					))}
				</text>
			)}
		</svg>
	);
};

InteractivePathPOI.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	xPosition: PropTypes.number.isRequired,
	yPosition: PropTypes.number.isRequired,
	stroke: PropTypes.string,
	strokeWidth: PropTypes.string,
	radius: PropTypes.number.isRequired,
	fill: PropTypes.string,
	angle: PropTypes.number.isRequired,
	lineLength: PropTypes.number.isRequired,
	icon: PropTypes.element,
	iconBackgroundFill: PropTypes.string,
	header: PropTypes.string,
	body: PropTypes.string,
	textAnchor: PropTypes.oneOf(["start", "middle", "end"]),
	contentOffset: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
	}),
	classes: PropTypes.string,
};

export default InteractivePathPOI;
