import React from "react";
import "../../styles/pages/case_studies/CaseStudies.scss";
import "../../styles/pages/Global.scss";
import SwiperCarousel from "../../components/SwiperCarousel";
import CaseStudy from "./CaseStudy";

const CaseStudies = () => {
	const importAll = (context) => context.keys().map(context);
	const caseStudyImages = importAll(require.context("../../assets/images/case_studies", false, /\.(jpg)$/));

	return (
		<div className="case-studies-page">
			<div className="carousel-container fade-in">
			<SwiperCarousel
				content={[
					<CaseStudy
						title={"NCS"}
						subtitle={<>National<br />Carwash<br />Solutions</>}
						header={"Carwash Experience"}
						body={
							"NCS partnered with Continuum to create an experience that displays the inner workings of their premium-quality wash components. This interactive platform not only provided a deeper understanding of how these parts function together, but also provided comprehensive guidance on performing maintenance tasks. By harnessing the power of AR technology, NCS turned dull, complex technical learning into an engaging and intuitive experience."
						}
						image={caseStudyImages[0]}
						videoSrc={"https://vimeo.com/757267601"}
					/>,
					<CaseStudy
						title={"Pura"}
						header={"Air Freshener"}
						body={
							"Pura, a premiere scent device company, approached Continuum to develop an experience that would promote their air freshener in a unique way. Through innovative AR technology, users can visualize how the device works and understand its convenience and effectiveness in enhancing their living spaces."
						}
						image={caseStudyImages[1]}
						videoSrc={"https://vimeo.com/543789628"}
					/>,
					<CaseStudy
						title={"Soapy Joes"}
						header={"Infinite Runner"}
						body={
							"Soapy Joe's Car Wash teamed up with Continuum to introduce 'Splash Dash,' a mobile AR game that entertained users while promoting a $10,000 giveaway. This collaboration demonstrates the blend of entertainment and marketing, boosting engagement and brand loyalty in a distinctive and memorable manner."
						}
						image={caseStudyImages[2]}
						videoSrc={"https://vimeo.com/870453491"}
					/>,
					<CaseStudy
						title={"Tree Of Life"}
						header={"Educational XR Experience"}
						body={
							"The Church of Jesus Christ of Latter-day Saints partnered with Continuum to tell the story of the Tree of Life. We combined AR tech with LDS scriptures to help users explore themes of faith and salvation in a vivid digital world. This project offers a modern way for people to connect with ancient scripture for spiritual growth and learning."
						}
						image={caseStudyImages[3]}
						videoSrc={"https://vimeo.com/812158957"}
					/>,
					<CaseStudy
						title={"Uponor"}
						header={"Water Heater Demo"}
						body={
							"Continuum & Uponor used innovative AR experience which demonstrates ence bachpere clumbing system. Users can gain a comprehensive understanding of how each part of the system functions and fits together. With this experience, Uponor and Continuum have empowered users to make informed decisions about their plumbing needs with confidence and clarity."
						}
						image={caseStudyImages[4]}
						videoSrc={"https://vimeo.com/845118152"}
					/>,
					<CaseStudy
						title={"Guitar Center"}
						header={"Van Halen Mural"}
						body={
							"Continuum collaborated with artist Robert Vargas to realize his homage to Eddie Van Halen through an interactive 50' mural in Los Angeles, California. Through this seamless immersive experience, spectators were able to engage with Vargas' tribute to the legendary musician in ways previously unimaginable, underscoring the transformative power of art and technology when combined."
						}
						image={caseStudyImages[5]}
						videoSrc={"https://vimeo.com/506270571"}
					/>,
				]}
				styles={{ height: "100%", pointerEvents: "none" }}
				slidesPerView={1}
				speed={0}
				leftToRight={false}
			/>
			</div>
		</div>
	);
};

export default CaseStudies;
