import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Vimeo from "@vimeo/player";
import "../styles/components/FullscreenVideoPlayer.scss";

const FullscreenVideoPlayer = ({ forwardRef, videoSrc, title, styles = {}, classes = "" }) => {
	const videoRef = useRef(null);
	const vimeoPlayer = useRef(null);

	const handleClose = () => {
		if (vimeoPlayer.current) {
			// Dispath the Exited Full Screen Video Event
			const exitedFullScreenVideo = new CustomEvent("ExitedFullScreenVideo", {});
			window.dispatchEvent(exitedFullScreenVideo);
			// Stop and hide the video
			vimeoPlayer.current
				.pause()
				.then(() => {
					videoRef.current.classList.add("hidden");
					vimeoPlayer.current.unload();
				})
				.catch((error) => {
					// console.error("Error while closing the player:", error);
				});
		}
	};

	useEffect(() => {
		if (videoRef.current) {
			vimeoPlayer.current = new Vimeo(videoRef.current, { url: videoSrc });

			vimeoPlayer.current.on("loaded", () => {
				// console.log("Vimeo player loaded");
			});

			vimeoPlayer.current.on("ended", () => {
				handleClose();
			});
		}

		return () => {
			if (vimeoPlayer.current) {
				vimeoPlayer.current.off("loaded");
				vimeoPlayer.current.off("ended");
				vimeoPlayer.current.unload().catch((error) => {
					console.error("Error while unloading the player:", error);
				});
			}
		};
	}, [videoSrc]);

	const handlePlayClick = () => {
		if (vimeoPlayer.current) {
			// Dispath the Entered Full Screen Video Event
			const enteredFullScreenVideo = new CustomEvent("EnteredFullScreenVideo", {});
			window.dispatchEvent(enteredFullScreenVideo);
			// Start the Video
			videoRef.current.classList.remove("hidden");
			vimeoPlayer.current.play().catch((error) => {
				console.error("Error while playing the video:", error);
			});
		}
	};

	return (
		<div ref={forwardRef} className={"fullscreen-video-player " + classes} style={styles}>
			<div className="play-button" onClick={handlePlayClick}>
				<svg viewBox="0 0 64 64" width="8vmin" height="8vmin">
					<circle cx="32" cy="32" r="30" stroke="black" strokeWidth="2" fill="none" />
					<polygon points="25,20 50,32 25,44" fill="black" />
				</svg>
				{title && <div className="play-title">{title}</div>}
			</div>
			<div ref={videoRef} className={`video-container hidden`} title="vimeo-player" onClick={handleClose}></div>
		</div>
	);
};

FullscreenVideoPlayer.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	videoSrc: PropTypes.string.isRequired,
	title: PropTypes.string,
	styles: PropTypes.object,
	classes: PropTypes.string,
};

export default FullscreenVideoPlayer;
