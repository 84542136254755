import React from "react";
import PropTypes from "prop-types";
import "../../styles/pages/case_studies/CaseStudy.scss";
import FullscreenVideoPlayer from "../../components/FullscreenVideoPlayer";

const CaseStudy = ({ forwardRef, title, subtitle, header, body, image, videoSrc }) => {
	return (
		<div className="case-study-container" ref={forwardRef}>
			<div className="case-study-title-container">
				<div className="case-study-title" style={{ justifyContent: subtitle ? "space-around" : "center" }}>
					{subtitle && <div style={{ width: "25%" }}></div>}
					{title}
					{subtitle && (
						<div className="case-study-subtitle">
							{subtitle}
							<div style={{ width: "25%" }}></div>
						</div>
					)}
				</div>
				<div className="case-study-title-spacer"></div>
			</div>
			<div className="case-study-info">
				<div className="case-study-text">
					<div className="case-study-header">{header}</div>
					<div className="case-study-body">{body}</div>
				</div>
				<div className="case-study-video">{videoSrc && <FullscreenVideoPlayer title={"Watch Video"} videoSrc={videoSrc} styles={{ flexDirection: "row" }} />}</div>
			</div>
			<img className="case-study-image" src={image} alt="Case Study Graphic" />
		</div>
	);
};

CaseStudy.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	title: PropTypes.string.isRequired,
	header: PropTypes.string,
	body: PropTypes.string,
	image: PropTypes.node,
	videoSrc: PropTypes.string,
};

export default CaseStudy;
