import React from "react";
import PropTypes from "prop-types";
import "../styles/components/InteractiveHeading.scss";

const InteractiveHeading = ({ forwardRef, content, drowdownHeader, dropdownBody, drowdownStyles = { textAlign: "left" }, outline, boxStyles = {}, classes = "", onClick }) => {
	return (
		<div className={"interactive-box " + classes} style={boxStyles} ref={forwardRef} onClick={onClick}>
			<div className="bounding-box" style={{ outline: outline }}>
				<div className="content-box">{content}</div>
			</div>
			<div className={"dropdown-content"} style={drowdownStyles}>
				<div>
					<div className="bounding-box-header">{drowdownHeader}</div>
					<div className="bounding-box-body">{dropdownBody}</div>
				</div>
			</div>
		</div>
	);
};

InteractiveHeading.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	content: PropTypes.node.isRequired,
	dropdownHeader: PropTypes.node,
	dropdownBody: PropTypes.node,
	dropdownStyles: PropTypes.object,
	outline: PropTypes.number,
	boxStyles: PropTypes.object,
	classes: PropTypes.string,
	onClick: PropTypes.func,
};

export default InteractiveHeading;
