import { useState, useEffect } from 'react';

function useMobileDevice() {
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        // Check if the user is on a mobile device
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        setIsMobileDevice(/iPhone|iPad|iPod|Android/i.test(userAgent));
    }, []);

    return isMobileDevice;
}

export default useMobileDevice;
