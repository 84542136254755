import React from "react";
import PropTypes from "prop-types";
import "../styles/components/PopUp.scss";

const Popup = ({ title, message, onClose }) => {
	return (
		<div className="popup-overlay" onClick={onClose}>
			<div className="popup" onClick={(e) => e.stopPropagation()}>
				<button className="popup-close" onClick={onClose}>
					&times;
				</button>
				<div className="popup-header">{title}</div>
				<div className="popup-message">
					{message.split("<br />").map((line, index) => (
						<span key={index}>
							{line}
							{index !== message.split("<br />").length - 1 && "\u00A0"}
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

Popup.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default Popup;
