import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import useMobileDevice from "../../hooks/UseMobileDevice";
import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

import "../../styles/pages/home/ParticleSystem.scss";
import particleMask from "../../assets/images/icons/particle-mask.svg";

const ParticleSystem = ({ forwardRef, classes = "", styles }) => {
	const [init, setInit] = useState(false);
	const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
	const isMobileDevice = useMobileDevice();

	useEffect(() => {
		const handleResize = () => {
			if (!isMobileDevice) {
				setScreenSize({ width: window.innerWidth, height: window.innerHeight });
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isMobileDevice]);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			await loadAll(engine);
			//await loadFull(engine);
			// await loadSlim(engine);
			//await loadBasic(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const particlesLoaded = (container) => {
		// Do something when the particles load
	};

	const calculateParticlesNumber = useCallback(
		(baseValue) => {
			const scaleFactor = screenSize.width / 1024;
			return Math.max(5, Math.round(baseValue * scaleFactor));
		},
		[screenSize]
	);

	const calculatePolygonScale = useCallback(() => {
		return screenSize.width / 1024;
	}, [screenSize]);

	const globalOptions = useMemo(
		() => ({
			background: {
				color: {
					value: "",
				},
			},
			fullScreen: false,
			fpsLimit: 30,
			polygon: {
				draw: {
					enable: false,
					stroke: {
						color: "#FF0000",
						width: 2,
						opacity: 1,
					},
				},
				move: {
					radius: 1,
				},
				inline: {
					arrangement: "equidistant",
				},
				scale: calculatePolygonScale(),
				type: "inside",
				url: particleMask,
				position: { x: "60", y: "65" },
			},
			detectRetina: true,
		}),
		[calculatePolygonScale]
	);

	const options1 = useMemo(
		() => ({
			...globalOptions,
			interactivity: {
				events: {
					onHover: {
						enable: !isMobileDevice,
						mode: "bubble",
					},
				},
				modes: {
					bubble: {
						distance: 100,
						duration: 0.4,
						opacity: 0.8,
						size: 40,
					},
				},
			},
			particles: {
				color: {
					value: "#c3113c",
				},
				links: {
					enable: false,
				},
				move: {
					direction: "none",
					enable: true,
					outModes: {
						default: "bounce",
					},
					random: false,
					speed: 0.75,
					straight: false,
				},
				number: {
					density: {
						enable: false,
					},
					value: calculateParticlesNumber(35),
				},
				opacity: {
					value: 1,
				},
				shape: {
					type: "polygon",
					options: {
						polygon: {
							sides: 6, // the number of sides of the polygon
						},
					},
				},
				size: {
					value: 3,
				},
			},
		}),
		[globalOptions, calculateParticlesNumber, isMobileDevice]
	);

	const options2 = useMemo(
		() => ({
			...globalOptions,
			particles: {
				links: {
					color: "#000000",
					distance: 250,
					enable: true,
					opacity: 0.75,
					width: 2.5,
				},
				move: {
					direction: "none",
					enable: true,
					outModes: {
						default: "bounce",
					},
					random: false,
					speed: 0.5,
					straight: false,
				},
				number: {
					density: {
						enable: true,
					},
					value: calculateParticlesNumber(15),
				},
				size: {
					value: 1,
				},
			},
		}),
		[globalOptions, calculateParticlesNumber]
	);

	const options3 = useMemo(
		() => ({
			...globalOptions,
			interactivity: {
				events: {
					onHover: {
						enable: !isMobileDevice,
						mode: "bubble",
					},
				},
				modes: {
					bubble: {
						distance: 100,
						duration: 0.4,
						opacity: 0.8,
						size: 40,
					},
				},
			},
			particles: {
				color: {
					value: "#c3113c",
				},
				links: {
					color: "#000000",
					distance: 100,
					enable: true,
					opacity: 1,
					width: 2.5,
				},
				move: {
					direction: "none",
					enable: true,
					outModes: {
						default: "bounce",
					},
					random: false,
					speed: 0.5,
					straight: false,
				},
				number: {
					density: {
						enable: false,
					},
					value: calculateParticlesNumber(25),
				},
				shape: {
					type: "polygon",
					options: {
						polygon: {
							sides: 6, // the number of sides of the polygon
						},
					},
				},
				size: {
					value: { min: 5, max: 10 },
				},
			},
		}),
		[globalOptions, calculateParticlesNumber, isMobileDevice]
	);

	return (
		<div ref={forwardRef} className={"particle-system-container " + classes} style={styles}>
			{init && (
				<>
					<Particles className="tsparticles" id="tsparticles1" particlesLoaded={particlesLoaded} options={options1} />
					<Particles className="tsparticles" id="tsparticles2" particlesLoaded={particlesLoaded} options={options2} />
					<Particles className="tsparticles" id="tsparticles3" particlesLoaded={particlesLoaded} options={options3} />
				</>
			)}
		</div>
	);
};

ParticleSystem.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	classes: PropTypes.string,
	styles: PropTypes.object,
};

export default ParticleSystem;

// Hexagonal Particles:

// const options = useMemo(
//     () => ({
//         fullScreen: false,
//         particles: {
//             color: {
//                 value: "#000000",
//                 animation: {
//                     enable: false,
//                     speed: 10,
//                 },
//             },
//             effect: {
//                 type: "trail",
//                 options: {
//                     trail: {
//                         length: 50,
//                         minWidth: 4,
//                     },
//                 },
//             },
//             move: {
//                 direction: "none",
//                 enable: true,
//                 outModes: {
//                     default: "destroy",
//                 },
//                 path: {
//                     clamp: false,
//                     enable: true,
//                     delay: {
//                         value: 0,
//                     },
//                     generator: "polygonPathGenerator",
//                     options: {
//                         sides: 6,
//                         turnSteps: 30,
//                         angle: 30,
//                     },
//                 },
//                 random: false,
//                 speed: 5,
//                 straight: false,
//             },
//             number: {
//                 value: 0,
//             },
//             opacity: {
//                 value: 1,
//             },
//             shape: {
//                 type: "polygon",
//                 options: {
//                     polygon: {
//                         sides: 6, // the number of sides of the polygon
//                     },
//                 },
//             },
//             size: {
//                 value: { min: 0, max: 3 },
//             },
//         },
//         emitters: {
//             direction: "none",
//             rate: {
//                 quantity: 1,
//                 delay: 0.25,
//             },
//             size: {
//                 width: 0,
//                 height: 0,
//             },
//             position: {
//                 x: 50,
//                 y: 50,
//             },
//         },
//     }),
//     []
// );
