import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { PopupProvider } from "./contexts/PopUpContext";
import Header from "./components/Header";
import Home from "./pages/home/Home";
import CaseStudies from "./pages/case_studies/CaseStudies";
import WhoWeAre from "./pages/who_we_are/WhoWeAre";
import Contact from "./components/Contact";
// import PageNotFound from "./pages/PageNotFound";

import continuumIcon from "./assets/images/icons/continuum-icon-black.svg";

const App = () => {
	const logo = <img src={continuumIcon} alt="Logo" />;

	const buttons = [
		{ label: "Case Studies", route: "/case-studies" },
		{ label: "Who We Are", route: "/who-we-are" },
		{ label: "Contact", route: "/contact" },
		// Add more buttons as needed
	];

	return (
		<PopupProvider>
			<ParallaxProvider>
				<Router>
					<div className="App">
						<Header logo={logo} buttons={buttons} />
						<Routes>
							{/* Home */}
							<Route path="/" element={<Home />} />
							<Route path="/case-studies" element={<CaseStudies />} />
							<Route path="/who-we-are" element={<WhoWeAre />} />
							<Route path="/contact" element={<Contact accentColor="#c3113c" classes="full-screen remove-header" />} />
							{/* Page Not Found */}
							{/* <Route path="/404" element={<PageNotFound />} /> */}
							<Route path="*" element={<Navigate to="/" />} />
						</Routes>
					</div>
				</Router>
			</ParallaxProvider>
		</PopupProvider>
	);
};

export default App;
