import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useDynamicPosition from "../../hooks/UseDynamicPosition";
import InteractiveHeading from "../../components/InteractiveHeading";
import FullscreenVideoPlayer from "../../components/FullscreenVideoPlayer";
import AnimatedPlus from "../../components/AnimatedPlus";
import ParticleSystem from "./ParticleSystem";
import InteractivePath from "../../components/interactive_path/InteractivePath";
import ParallaxBackground from "../../components/ParallaxBackground";
import HorizontalScrollCarousel from "../../components/HorizontalScrollCarousel";
import SwiperCarousel from "../../components/SwiperCarousel";
import Contact from "../../components/Contact";
import { path } from "./HomePathData";

import "../../styles/pages/home/Home.scss";
import "../../styles/pages/Global.scss";

import continuumBackground from "../../assets/videos/background/continuum-background.mp4";
import useMobileDevice from "../../hooks/UseMobileDevice";

const Home = () => {
	const navigate = useNavigate();

	const immersiveMarketingRef = useRef(null);
	const particleSystemRef = useRef(null);
	const { elementRef: seeOurWorkRef, topValue: seeOurWorkRefTop } = useDynamicPosition(particleSystemRef, 0.2, 0);
	const { elementRef: servicesRef, topValue: servicesRefTop } = useDynamicPosition(particleSystemRef, 0, 48);
	const { elementRef: interactivePathRef, topValue: interactivePathRefTop } = useDynamicPosition(servicesRef, 0.2);
	const { elementRef: parallaxRef, topValue: parallaxRefTop } = useDynamicPosition(interactivePathRef, 0.6);
	const { elementRef: ourWorkRef, topValue: ourWorkRefTop } = useDynamicPosition(parallaxRef, 0.35);
	const { elementRef: letsGoRef, topValue: letsGoRefTop } = useDynamicPosition(ourWorkRef);
	const { elementRef: contactRef, topValue: contactRefTop } = useDynamicPosition(letsGoRef);
	const animatedPlusRef1 = useRef(null);
	const animatedPlusRef2 = useRef(null);
	const { elementRef: animatedPlusRef3, topValue: animatedPlusRef3Top } = useDynamicPosition(parallaxRef, 0.6, 0);
	const { elementRef: animatedPlusRef4, topValue: animatedPlusRef4Top } = useDynamicPosition(parallaxRef, 0, 0);
	const { elementRef: animatedPlusRef5, topValue: animatedPlusRef5Top } = useDynamicPosition(letsGoRef, 0.2, 0);

	const isMobileDevice = useMobileDevice();

	const importAll = (context) => context.keys().map(context);
	const offsetArrayByHalf = (array) => {
		const midpoint = Math.ceil(array.length / 2);
		const firstHalf = array.slice(0, midpoint);
		const secondHalf = array.slice(midpoint);
		return [...secondHalf, ...firstHalf];
	};
	const carouselVideos = importAll(require.context("../../assets/videos/carousel", false, /\.(mp4|webm|ogg)$/));
	const clientLogos = importAll(require.context("../../assets/images/logos", false, /\.(png)$/));

	return (
		<div className="home-page">
			{/* Immersive Marketing Section */}
			<InteractiveHeading
				forwardRef={immersiveMarketingRef}
				content={
					<>
						Immersive
						<br />M<span>ar</span>keting
					</>
				}
				boxStyles={{ alignItems: "flex-start", margin: "5vh 5vw", zIndex: "10" }}
				drowdownStyles={{ textAlign: "left", left: "20px" }}
				drowdownHeader={"Augmented Reality"}
				dropdownBody={"Our immersive experiences revolutionize marketing, education, and entertainment, making interactions more engaging and impactful."}
				onClick={() => {
					// Scroll to the target position
					window.scrollTo({ top: 0, behavior: "smooth" });
				}}
			/>
			{/* Particle System Section */}
			<ParticleSystem forwardRef={particleSystemRef} classes="fade-in" />
			{/* See Our Work Video Section */}
			<FullscreenVideoPlayer forwardRef={seeOurWorkRef} videoSrc={"https://player.vimeo.com/video/899593815?h=cdadf2a06d"} title={"See Our Work"} styles={{ top: seeOurWorkRefTop, left: "60%", right: 0, flexDirection: "column" }} />
			{/* Services Section */}
			<InteractiveHeading
				forwardRef={servicesRef}
				content={
					<>
						<span>Services</span>
					</>
				}
				boxStyles={{ top: servicesRefTop, alignItems: "flex-end", zIndex: 1, right: "5vw", marginBottom: 0 }}
				drowdownStyles={{ textAlign: "left", width: "65%", right: "0" }}
				drowdownHeader={"Need a fresh way to engage your audience?"}
				dropdownBody={"We plan, develop, create, and launch results-driven, immersive experiences. Our process ensures impactful interactions with solutions tailored to your needs."}
				onClick={() => {
					// Get the section to scroll
					const sectionToScroll = document.getElementsByClassName("interactive-box")[1];
					// Calculate the target scroll position with an offset of 90 pixels from the top
					const targetPosition = sectionToScroll.offsetTop - 90;
					// Scroll to the target position
					window.scrollTo({ top: targetPosition, behavior: "smooth" });
				}}
				classes="fade-in"
			/>
			{/* Interactive Path Section */}
			<InteractivePath forwardRef={interactivePathRef} path={path.path} poiData={path.poiData} viewBox="0 0 1925 775" scrollAnimated={true} styles={{ top: interactivePathRefTop, width: "108vw", left: "-8vw" }} classes="fade-in" />
			{/* Parallax Section */}
			<ParallaxBackground forwardRef={parallaxRef} content={continuumBackground} type="video" maskShape="polygon(0% 0%, 0% 100%, 30% 50%)" speed={-24} height="50vw" styles={{ maxHeight: "100vh", top: parallaxRefTop }} classes="fade-in" />
			{/* Our Work Section */}
			<HorizontalScrollCarousel
				forwardRef={ourWorkRef}
				content={[<></>, ...carouselVideos]}
				slidesPerView={isMobileDevice ? 2.5 : 7}
				contentStyles={{ minHeight: "100vmin", top: "10vh" }}
				styles={{ top: ourWorkRefTop }}
				scrollProgressMapping={[0, 0.85]}
				header={
					<>
						<div style={{ display: "flex", boxSizing: "border-box" }}>
							<div style={{ width: "100%" }}></div>
							<InteractiveHeading
								content={
									<>
										<span>Our Work</span>
									</>
								}
								drowdownHeader={""}
								dropdownBody={"Here are some of the coolest, and most successful Augmented Reality experiences we've developed for our happy clients."}
								boxStyles={{ display: "flex", flexDirection: "column", margin: "20px 5vw 0 0", position: "unset", alignItems: "flex-end", zIndex: 1, fontSize: "6.75vw" }}
								drowdownStyles={{ textAlign: "right", position: "relative" }}
								onClick={() => {
									const sectionToScroll = document.getElementsByClassName("carousel-div-container")[0];
									sectionToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
								}}
							/>
						</div>
					</>
				}
				footer={
					<>
						<div style={{ display: "flex", boxSizing: "border-box" }}>
							<div style={{ width: "100%" }}></div>
							<InteractiveHeading
								content={
									<>
										<span>See More Case Studies &#9658;</span>{" "}
									</>
								}
								boxStyles={{ position: "unset", zIndex: "1", fontSize: "3.5vmin", margin: "8px 0 0 0" }}
								outline={0}
								onClick={() => {
									navigate("/case-studies");
								}}
							/>
						</div>
						<SwiperCarousel content={clientLogos} styles={{ position: "unset", margin: "20px 0px 0px 0px", pointerEvents: "none" }} slidesPerView={isMobileDevice ? 4 : 12} speed={15000} leftToRight={true} />
						<SwiperCarousel content={offsetArrayByHalf(clientLogos)} styles={{ position: "unset", margin: "0px 0px 20px 0", pointerEvents: "none" }} slidesPerView={isMobileDevice ? 4 : 12} speed={15000} />
					</>
				}
				classes="fade-in"
			/>
			{/* Let's Go Section */}
			<div ref={letsGoRef} className="lets-go-container fade-in" style={{ top: letsGoRefTop }}>
				<InteractiveHeading
					content={
						<>
							Let's <span>Go</span>
						</>
					}
					boxStyles={{ alignItems: "flex-end", zIndex: 1, marginBottom: "48px" }}
					drowdownStyles={{ textAlign: "right", width: "75%", right: "0" }}
					drowdownHeader={""}
					dropdownBody={"Contact us today to get started."}
					onClick={() => {
						const sectionToScroll = document.getElementsByClassName("contact-parent-container")[0];
						sectionToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
					}}
				/>
			</div>
			{/* Contact Us Section */}
			<Contact forwardRef={contactRef} accentColor="#c3113c" styles={{ top: contactRefTop }} classes="fade-in" />
			{/* Animated Plus Section */}
			<AnimatedPlus forwardRef={animatedPlusRef1} styles={{ position: "absolute", top: "132px", right: "3%", width: "9vmin", height: "9vmin" }} />
			<AnimatedPlus forwardRef={animatedPlusRef2} styles={{ position: "absolute", top: seeOurWorkRefTop, left: "10%" }} />
			<AnimatedPlus forwardRef={animatedPlusRef3} styles={{ position: "absolute", top: animatedPlusRef3Top, right: "10%" }} />
			<AnimatedPlus forwardRef={animatedPlusRef4} styles={{ position: "absolute", top: animatedPlusRef4Top, left: "2%" }} />
			<AnimatedPlus forwardRef={animatedPlusRef5} styles={{ position: "absolute", top: animatedPlusRef5Top, left: "2%", width: "9vmin", height: "9vmin" }} />
		</div>
	);
};

export default Home;
