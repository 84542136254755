import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ParallaxBanner } from "react-scroll-parallax";
import "../styles/components/ParallaxBackground.scss";

const ParallaxBackground = ({ forwardRef, content, type = "image", speed = -35, height = "85vh", maskShape = "polygon(0% 0%, 0% 100%, 30% 50%)", styles, onClick, classes = "" }) => {
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	useEffect(() => {
		if (isFirstLoad) {
			const timer = setTimeout(() => {
				setIsFirstLoad(false);
			}, 250);

			return () => clearTimeout(timer); // Clean up the timer if component unmounts before timeout
		}
	}, [isFirstLoad]);

	const layers =
		type === "image"
			? [{ image: content, speed }]
			: [
					{
						children: <video src={content} autoPlay loop muted playsInline controlsList="nodownload" controls={false} disableRemotePlayback style={{pointerEvents: "none", width: "100%", height: "100%", objectFit: "cover" }} />,
						speed,
					},
			  ];

	return (
		<div className={"parallax-container " + classes} style={{ height, ...styles }} ref={forwardRef}>
			<div className="parallax-mask" style={{ clipPath: maskShape }} onClick={onClick}>
				{!isFirstLoad && <ParallaxBanner className="parallax-banner" layers={layers} />}
			</div>
		</div>
	);
};

ParallaxBackground.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	content: PropTypes.node.isRequired,
	type: PropTypes.oneOf(["image", "video"]),
	speed: PropTypes.number,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maskShape: PropTypes.string,
	styles: PropTypes.object,
	onClick: PropTypes.func,
	classes: PropTypes.string,
};

export default ParallaxBackground;
