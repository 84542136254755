import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "../styles/components/Contact.scss";
import "../styles/pages/Global.scss";

// Remove the default global interceptor for logging errors

const Contact = ({ forwardRef, accentColor = "white", styles = {}, classes = "" }) => {
	const formInfo = {
		url: "https://docs.google.com/forms/d/e/1FAIpQLSck-MJUs3a3yCE0JVhLdmc6Fc8VpiFc__PEEcDXi1BLK3BfmA/formResponse",
		firstName: "entry.1783072317",
		lastName: "entry.2138619055",
		email: "entry.2078778415",
		subject: "entry.883717116",
		message: "entry.147158109",
	};

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		subject: "",
		message: "",
	});

	const [errors, setErrors] = useState({});
	const [submitted, setSubmitted] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const validate = () => {
		const newErrors = {};
		if (!formData.firstName || !formData.lastName) newErrors.firstName = "Name is required";
		if (!formData.lastName) newErrors.lastName = "";
		if (!formData.email) newErrors.email = "Email is required";
		if (!formData.subject) newErrors.subject = "Subject is required";
		if (!formData.message) newErrors.message = "Message is required";
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validate()) {
			const formPayload = new FormData();

			formPayload.append(formInfo.firstName, formData.firstName);
			formPayload.append(formInfo.lastName, formData.lastName);
			formPayload.append(formInfo.email, formData.email);
			formPayload.append(formInfo.subject, formData.subject);
			formPayload.append(formInfo.message, formData.message);

			try {
				await axios.post(formInfo.url, formPayload, {
					withCredentials: false, // Set CORS mode to "anonymous"
				});
			} catch (error) {
				// Handle the error appropriately
			}

			setFormData({ firstName: "", lastName: "", email: "", subject: "", message: "" });
			setSubmitted(true);

			setTimeout(() => {
				setSubmitted(false);
			}, 3500);
		}
	};

	return (
		<div className={"contact-parent-container " + classes} ref={forwardRef} style={styles}>
			<div className="contact-header fade-in">Contact</div>
			<div className="contact-content-container fade-in">
				<div className="contact-info-container">
					<div className="contact-info-row">
						<div className="contact-info-icon-container">
							<svg className="contact-info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<path stroke={accentColor} strokeLinecap="round" strokeWidth="0.5" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
							</svg>
						</div>
						<div className="contact-info">contact@thenuum.com</div>
					</div>
					<div className="contact-info-row">
						<div className="contact-info-icon-container">
							<svg className="contact-info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<path
									stroke={accentColor}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="0.5"
									d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
								/>
							</svg>
						</div>
						<div className="contact-info">801-477-7857</div>
					</div>
					<div className="contact-info-row">
						<div className="contact-info-icon-container">
							<svg className="contact-info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<path stroke={accentColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
								<path
									stroke={accentColor}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="0.5"
									d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
								/>
							</svg>
						</div>
						<div className="contact-info">
							730 Sleepy Ridge Dr
							<br />
							Suite 001
							<br />
							Orem, UT 84059
						</div>
					</div>
				</div>
				<div className="contact-form-container">
					<form className="contact-form" onSubmit={handleSubmit} noValidate>
						<div className="form-name-group">
							<div className="form-group first-name">
								<label htmlFor="firstName">First Name</label>
								<input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />
								{errors.firstName && <p className="required">{errors.firstName}</p>}
							</div>
							<div className="form-group last-name">
								<label htmlFor="lastName">Last Name</label>
								<input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} />
								{errors.lastName && <p className="required">{errors.lastName}</p>}
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
							{errors.email && <p className="required">{errors.email}</p>}
						</div>
						<div className="form-group">
							<label htmlFor="subject">Subject</label>
							<input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
							{errors.subject && <p className="required">{errors.subject}</p>}
						</div>
						<div className="form-group">
							<label htmlFor="message">Message</label>
							<textarea id="message" name="message" value={formData.message} onChange={handleChange} />
							{errors.message && <p className="required">{errors.message}</p>}
						</div>
						<button className={`submit-form-button ${submitted && "submitted"}`} style={{ color: accentColor, borderColor: accentColor }} type="submit">
							{!submitted && <>Submit</>}
							{submitted && <>Submitted</>}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

Contact.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	accentColor: PropTypes.string,
	styles: PropTypes.object,
	classes: PropTypes.string,
};

export default Contact;
