import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion, useTransform, useScroll } from "framer-motion";
import "../styles/components/HorizontalScrollCarousel.scss";
import useMobileDevice from "../hooks/UseMobileDevice";

const HorizontalScrollCarousel = ({ forwardRef, content = [], shuffle = false, height = 300, contentStyles, slidesPerView = 4, styles, scrollProgressMapping = [0, 1], scrollSidePadding = 0, header, footer, classes = "" }) => {
	const targetRef = useRef(null);
	const [slides, setSlides] = useState([]);
	const { scrollYProgress } = useScroll({
		target: targetRef,
	});

	const isMobileDevice = useMobileDevice();

	// Calculate the width of each element
	const elementWidth = `${100 / slidesPerView}vw`;
	// Calculate the x scroll progress
	const x = useTransform(scrollYProgress, scrollProgressMapping, [`${scrollSidePadding}%`, `-${(100 * (height - height / (content.length / slidesPerView))) / height + scrollSidePadding}%`]);

	useEffect(() => {
		if (shuffle) {
			const shuffledContent = [...content].sort(() => Math.random() - 0.5);
			setSlides(shuffledContent);
		} else {
			setSlides(content);
		}
	}, [content, shuffle]);

	return (
		<div className={"carousel-parent " + classes} style={styles} ref={forwardRef}>
			<section ref={targetRef} className="carousel-section-container" style={{ height: `${height}vh` }}>
				<div className="carousel-div-container" style={contentStyles}>
					{header && header}
					<div className="carousel-slides-container">
						<motion.div style={{ x }} className="carousel-motion-container">
							{slides &&
								slides.map((item, index) => (
									<div key={index} className="carousel-element" style={{ width: elementWidth }}>
										<div className="carousel-content-container">
											{typeof item === "string" && item.match(/\.(jpeg|jpg|gif|png|svg)$/) != null ? (
												<img key={`image-${index}`} className="carousel-content" src={item} alt="Slide" />
											) : typeof item === "string" && item.match(/\.(mp4)$/) != null ? (
												<video
													key={`video-${index}`}
													className="carousel-content"
													style={isMobileDevice ? { pointerEvents: "none" } : {}}
													autoPlay={isMobileDevice}
													loop
													muted
													controlsList="nodownload"
													controls={false}
													playsInline
													disableRemotePlayback
													onMouseEnter={(e) => {
														if (!isMobileDevice) {
															e.target.play();
														}
													}}
													onMouseLeave={(e) => {
														if (!isMobileDevice) {
															e.target.pause();
														}
													}}
												>
													<source src={item} type="video/mp4" />
													Your browser does not support the video tag.
												</video>
											) : (
												// Handle other types of content here
												<div>{item}</div>
											)}
										</div>
									</div>
								))}
						</motion.div>
					</div>
					{footer && footer}
				</div>
			</section>
		</div>
	);
};

HorizontalScrollCarousel.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	content: PropTypes.arrayOf(PropTypes.node),
	shuffle: PropTypes.bool,
	height: PropTypes.number,
	contentStyles: PropTypes.object,
	slidesPerView: PropTypes.number,
	styles: PropTypes.object,
	scrollProgressMapping: PropTypes.arrayOf(PropTypes.number),
	scrollSidePadding: PropTypes.number,
	header: PropTypes.node,
	footer: PropTypes.node,
	classes: PropTypes.string,
};

export default HorizontalScrollCarousel;
