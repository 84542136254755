import React from "react";
import "../../styles/pages/who_we_are/WhoWeAre.scss";
import "../../styles/pages/Global.scss";

import continuumBackground from "../../assets/images/backgrounds/continuum-mural.jpg";

const WhoWeAre = () => {
	return (
		<div className="who-we-are-page">
			{/* Parallax Section */}
			<img className="background-image fade-in-15" alt="Background" src={continuumBackground} />
			{/* Heading */}
			<div className="heading fade-in">A Team on the Cutting Edge of <span>Immersion</span></div>
			{/* Subheading */}
			<div className="subheading fade-in">Comprised of developers, artists, and XR specialists, our team works collaboratively to bring your vision to life.<br></br><br></br>We come from backgrounds in gaming, movies, animation, business, and marketing.<br></br><br></br>We love what we do, we love to create, we love to build. Let's build something together!</div>
		</div>
	);
};

export default WhoWeAre;
