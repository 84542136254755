import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/components/SwiperCarousel.scss";

const SwiperCarousel = ({ forwardRef, content, styles, shuffle, leftToRight = false, slidesPerView = 4, speed = 0, classes = "" }) => {
	const [slides, setSlides] = useState([]);

	const isImageFile = (item) => typeof item === "string" && item.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
	const isImageDataUrl = (item) => typeof item === "string" && item.startsWith("data:image/");
	const isVideoFile = (item) => typeof item === "string" && item.match(/\.(mp4)$/) != null;

	useEffect(() => {
		if (shuffle) {
			const shuffledContent = [...content].sort(() => Math.random() - 0.5);
			setSlides(shuffledContent);
		} else {
			setSlides(content);
		}
	}, [content, shuffle]);

	useEffect(() => {
		const setEnableButtons = (enabled) => {
			const prevButton = document.querySelector(".swiper-button-prev");
			const nextButton = document.querySelector(".swiper-button-next");
			const style = enabled ? "all" : "none";
			if (prevButton) {
				prevButton.style.pointerEvents = style;
				prevButton.style.pointerEvents = style;
			}
			if (nextButton) {
				nextButton.style.pointerEvents = style;
				nextButton.style.pointerEvents = style;
			}
		};

		// Event handler for entering full screen
		const handleEnteredFullScreenVideo = (event) => {
			// console.log("Entered full screen:", event.detail);
			setEnableButtons(false);
		};

		// Event handler for exiting full screen
		const handleExitedFullScreenVideo = (event) => {
			// console.log("Exited full screen:", event.detail);
			setEnableButtons(true);
		};

		// Adding the event listeners when the component mounts
		window.addEventListener("EnteredFullScreenVideo", handleEnteredFullScreenVideo);
		window.addEventListener("ExitedFullScreenVideo", handleExitedFullScreenVideo);

		// Cleanup function to remove the event listeners when the component unmounts
		return () => {
			window.removeEventListener("EnteredFullScreenVideo", handleEnteredFullScreenVideo);
			window.removeEventListener("ExitedFullScreenVideo", handleExitedFullScreenVideo);
		};
	}, []); // The empty array ensures this effect runs only once when the component mounts

	const renderContent = (item, index) => {
		if (isImageFile(item) || isImageDataUrl(item)) {
			return <img key={`image-${index}`} className="swiper-content" src={item} alt="Slide" />;
		} else if (isVideoFile(item)) {
			return (
				<video
					key={`video-${index}`}
					className="swiper-content"
					autoPlay={false}
					loop
					muted
					onMouseEnter={(e) => {
						e.target.play();
					}}
					onMouseLeave={(e) => {
						e.target.pause();
					}}
				>
					<source src={item} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			);
		} else {
			// Handle other types of content here
			return <div key={`other-${index}`}>{item}</div>;
		}
	};

	return (
		<div ref={forwardRef} className={classes}>
			{slides && (
				<Swiper
					className="swiper-container"
					modules={[...(speed === 0 ? [Navigation, Pagination] : [Autoplay])]}
					spaceBetween={0}
					slidesPerView={slidesPerView}
					style={{
						transform: `${leftToRight ? "scale(-1)" : "scale(1)"}`,
						transitionTimingFunction: `${speed !== 0 ? "linear" : "var(--swiper-wrapper-transition-timing-function, initial)"}`,
						...styles,
					}}
					loop={true}
					autoplay={{ delay: 0, disableOnInteraction: false }}
					speed={speed === 0 ? 500 : speed}
					grabCursor={false}
					navigation={speed === 0}
					pagination={speed === 0 ? { clickable: true } : false}
				>
					{slides.map((item, index) => (
						<SwiperSlide key={`swiper-${index}`} style={{ transform: `${leftToRight ? "scale(-1)" : "scale(1)"}` }}>
							{renderContent(item, index)}
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</div>
	);
};

SwiperCarousel.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	content: PropTypes.arrayOf(PropTypes.node).isRequired,
	styles: PropTypes.object,
	shuffle: PropTypes.bool,
	leftToRight: PropTypes.bool,
	slidesPerView: PropTypes.number,
	speed: PropTypes.number,
	classes: PropTypes.string,
};

export default SwiperCarousel;
