import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/components/Header.scss";

const Header = ({ forwardRef, logo, buttons, classes = "" }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();

	const boxShadow = scrollPosition <= 16 ? "none" : `0 ${scrollPosition * 0.1 < 16 ? scrollPosition * 0.1 : 16}px 24px rgba(0, 0, 0, 0.2)`;

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			setScrollPosition(position);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 768 && menuOpen) {
				setMenuOpen(false);
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [menuOpen]);

	return (
		<div className={"header-wrapper " + classes} ref={forwardRef}>
			{/* Header */}
			<div className="floating-header" style={{ boxShadow }}>
				{logo && (
					<div
						className="logo"
						onClick={() => {
							navigate("/");
						}}
					>
						{logo}
					</div>
				)}
				<div className="hamburger" onClick={toggleMenu}>
					<div className={`hamburger-line ${menuOpen ? "open" : ""}`}></div>
					<div className={`hamburger-line ${menuOpen ? "open" : ""}`}></div>
					<div className={`hamburger-line ${menuOpen ? "open" : ""}`}></div>
				</div>
				{buttons && (
					<div className={`nav-buttons ${menuOpen ? "open" : ""}`}>
						{buttons.map((button, index) => (
							<NavLink key={index} to={button.route} className="nav-button">
								{button.label}
							</NavLink>
						))}
					</div>
				)}
			</div>
			{/* Blur div */}
			<div className="blur-header"></div>
			{/* Spacer div */}
			<div className="spacer-header" />
		</div>
	);
};

Header.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	logo: PropTypes.node.isRequired,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			route: PropTypes.string.isRequired,
		})
	).isRequired,
	classes: PropTypes.string,
};

export default Header;
