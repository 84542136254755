import React from "react";
import PropTypes from "prop-types";
import "../styles/components/AnimatedPlus.scss";

const AnimatedPlus = ({ forwardRef, styles = {}, onClick, classes = "" }) => {
	return (
		<svg ref={forwardRef} className={"animated-plus " + classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={styles} onClick={onClick}>
			<g>
				<path strokeWidth="1" d="M24,10V38M10,24H38" />
			</g>
		</svg>
	);
};

AnimatedPlus.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	styles: PropTypes.object,
	onClick: PropTypes.func,
    classes: PropTypes.string,
};

export default AnimatedPlus;
