import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import InteractivePathPOI from "./InteractivePathPOI";
import "../../styles/components/interactive_path/InteractivePath.scss";

const InteractivePath = ({ forwardRef, path, poiData = [], viewBox = "0 0 1024 1024", scrollAnimated = true, styles = {}, classes = "" }) => {
	const [scrollY, setScrollY] = useState(0);
	const pathContainerRef = useRef(null);
	const scrollingTimeout = useRef(null); // Use useRef to store the timeout

	const handleScroll = useCallback(() => {
		if (scrollAnimated) {
			setScrollY(window.scrollY);
		}
		// Apply special styling while the user is scrolling
		// Clear the previous timeout
		clearTimeout(scrollingTimeout.current);
		// Add the class immediately
		pathContainerRef.current.classList.add("scrolling");
		// Set a timeout to remove the class after a delay
		scrollingTimeout.current = setTimeout(() => {
			pathContainerRef.current.classList.remove("scrolling");
		}, 250); // Adjust the delay according to your preference
	}, [scrollAnimated]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollAnimated, handleScroll]);

	const getTransition = () => {
		return scrollAnimated
			? {
					duration: 0,
					ease: "easeInOut",
					repeat: 0,
					repeatType: "loop",
					repeatDelay: 1,
			  }
			: {
					duration: 5,
					ease: "easeInOut",
					repeat: Infinity,
					repeatType: "loop",
					repeatDelay: 1,
			  };
	};

	return (
		<div className={"path-container " + classes} ref={forwardRef} style={styles}>
			<div className="path" ref={pathContainerRef}>
				<motion.svg style={{ overflow: "visible" }} viewBox={viewBox}>
					<motion.path
						initial={{ strokeDashoffset: scrollAnimated ? -scrollY : 0 }}
						animate={{ strokeDashoffset: scrollAnimated ? -scrollY : -200 }}
						transition={getTransition()}
						strokeWidth={4}
						strokeDasharray="10 10" // (line length, line spacing)
						fill="none"
						d={path}
					/>
					{poiData &&
						poiData.map((data, index) => (
							<InteractivePathPOI
								key={index}
								xPosition={data.xPosition}
								yPosition={data.yPosition}
								radius={24}
								fill="#FFF"
								angle={data.angle}
								lineLength={data.lineLength}
								icon={data.icon}
								header={data.header}
								body={data.body}
								textAnchor={data.textAnchor}
								contentOffset={data.contentOffset}
							/>
						))}
				</motion.svg>
			</div>
		</div>
	);
};

InteractivePath.propTypes = {
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	path: PropTypes.string.isRequired,
	poiData: PropTypes.arrayOf(PropTypes.object),
	viewBox: PropTypes.string,
	scrollAnimated: PropTypes.bool,
	styles: PropTypes.object,
	classes: PropTypes.string,
};

export default InteractivePath;
