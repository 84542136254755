import React, { createContext, useState, useContext } from "react";
import Popup from "../components/PopUp";

const PopupContext = createContext();

export const usePopupContext = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [popupContent, setPopupContent] = useState({ title: "", message: "" });

	const showPopup = ({ title, message }) => {
		setPopupContent({ title, message });
		setIsVisible(true);
	};

	const hidePopup = () => {
		setIsVisible(false);
		setPopupContent({ title: "", message: "" });
	};

	return (
		<PopupContext.Provider value={{ showPopup, hidePopup }}>
			{children}
			{isVisible && <Popup title={popupContent.title} message={popupContent.message} onClose={hidePopup} />}
		</PopupContext.Provider>
	);
};
