import { useState, useEffect, useRef, useCallback } from "react";

const useDynamicPosition = (refBelow, refBelowOverlapPercentage = 0, spacing = 0) => {
  const [topValue, setTopValue] = useState(0);
  const elementRef = useRef(null);

  const updatePosition = useCallback(() => {
    if (refBelow.current) {
      const belowElementHeight = refBelow.current.offsetHeight;
      const belowElementMarginBottom = parseInt(window.getComputedStyle(refBelow.current).marginBottom);
      const refBelowOverlapOffset = belowElementHeight * refBelowOverlapPercentage;
      const belowElementBottomPosition = refBelow.current.offsetTop + belowElementHeight + belowElementMarginBottom - refBelowOverlapOffset + spacing;
      setTopValue(belowElementBottomPosition);
    }
  }, [refBelow, refBelowOverlapPercentage, spacing]);

  useEffect(() => {
    const currentRefBelow = refBelow.current;

    // Create a ResizeObserver to observe changes in the dimensions of refBelow
    const resizeObserver = new ResizeObserver(() => {
      updatePosition();
    });

    // Create a MutationObserver to observe changes in the position of refBelow
    const mutationObserver = new MutationObserver(() => {
      updatePosition();
    });

    if (currentRefBelow) {
      resizeObserver.observe(currentRefBelow);
      mutationObserver.observe(currentRefBelow, {
        attributes: true,
        attributeFilter: ["style", "class"], // Observing style and class changes
        subtree: false,
      });
    }

    // Update position on initial load
    updatePosition();

    // Update position on window resize
    window.addEventListener("resize", updatePosition);

    // Cleanup the event listeners and observers
    return () => {
      window.removeEventListener("resize", updatePosition);
      if (currentRefBelow) {
        resizeObserver.unobserve(currentRefBelow);
        mutationObserver.disconnect();
      }
    };
  }, [refBelow, updatePosition]);

  return { elementRef, topValue };
};

export default useDynamicPosition;
